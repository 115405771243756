import '@stimulus/polyfills';
import 'intersection-observer';
if (Element.prototype.replaceChildren === undefined) {
    Element.prototype.replaceChildren = function (...nodes) {
        while (this.lastChild) {
            this.removeChild(this.lastChild);
        }
        if (nodes) {
            this.append(...nodes);
        }
    };
}
